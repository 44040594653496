import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Hero, About, Jobs, Featured, Projects, Contact } from '@components';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <StyledMainContainer className="fillHeight">
      <br/><br/>
      <br/><br/>
    <h1>AVISO LEGAL</h1>

<h2>1. INFORMACIÓN LEGAL</h2>
<p>A continuación, se indican los datos de información general de este SITIO WEB:</p>
<p><strong>Titular:</strong> RACKS LABS S.L.</p>
<p><strong>NRT:</strong> L-717403-B</p>
<p><strong>Dirección:</strong> Avinguda pont de la Tosca local 3, Escaldes</p>
<p><strong>Correo electrónico:</strong> hello@rackslabs.com</p>
<p>La visita a este SITIO WEB atribuye la condición de “USUARIO” al visitante, con independencia de la condición de “CLIENTE” que este usuario pueda tener con RACKS LABS, S.L. El objetivo de este SITIO WEB es ofrecer a los CLIENTES y/o USUARIOS información relativa a productos y servicios de RACKS LABS, S.L. El USUARIO, mediante el acceso a todos los contenidos que forman parte del SITIO WEB, puede visitar, obtener información de productos y servicios, y dirigir solicitudes y/o comunicaciones a RACKS LABS, S.L. Este acceso implica la aceptación llena y sin reservas de todas las disposiciones y los términos de uso que contiene este “Aviso legal”.</p>

<h2>2. PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
<p>Todos los contenidos del SITIO WEB, entendiendo por estos a título meramente enunciativo, los textos, fotografías, gráficos, imágenes, iconos, tecnología, software, enlaces y otros contenidos audiovisuales o sonoros, así como su diseño gráfico y códigos fuente, son propiedad intelectual de RACKS LABS, S.L. o de terceros.</p>
<p>Las marcas, nombres comerciales o signos distintivos son titularidad de RACKS LABS, S.L o terceros, sin que pueda entenderse que el acceso al SITIO WEB atribuya ningún derecho sobre las marcas, nombres comerciales y/o signos distintivos.</p>

<h2>3. CONDICIONES DE USO DEL SITIO WEB</h2>
<p>CONDICIONES GENERALES: El USUARIO se obliga a hacer un uso correcto del SITIO WEB en conformidad con la Ley y las presentes condiciones de uso. El USUARIO responderá frente a RACKS LABS, S.L, o frente a terceros, de cualesquier daños y perjuicios que pudieran acontecer a consecuencia del incumplimiento de esta obligación.</p>

<h2>4. EXCLUSIÓN DE RESPONSABILIDAD</h2>
<p>INFORMACIÓN: El acceso al SITIO WEB no implica la obligación por parte de RACKS LABS, S.L. de comprobar la veracidad, exactitud, adecuación, idoneidad, exhaustividad y actualidad de la información suministrada a través de este.</p>
<p>CALIDAD DEL SERVICIO: El acceso al SITIO WEB no implica la obligación por parte de RACKS LABS, S.L. de controlar la ausencia de virus, gusanos o cualquier otro elemento informático nocivo.</p>
<p>DISPONIBILIDAD DEL SERVICIO: El acceso al SITIO WEB requiere servicios y suministros de terceros, incluidas las redes de telecomunicaciones.</p>

<h2>5. PROTECCIÓN DE DATOS</h2>
<p>Ver la correspondiente “Política de Privacidad”.</p>

<h2>6. JURISDICCIÓN Y LEY APLICABLE</h2>
<p>Este Aviso Legal se rige por la legislación andorrana. Los USUARIOS de este SITIO WEB se someten a la jurisdicción andorrana, con renuncia exprés a cualquier otro fuero que, conforme a Derecho, los pudiera corresponder.</p>
<p>RACKS LABS, S.L se reserva la facultad de efectuar, en cualquier momento y sin necesidad de aviso previo, modificaciones y actualizaciones de la información que contiene el SITIO WEB o de la configuración y/o presentación de este.</p>
<p>Última actualización: 18 de agosto de 2023.</p>
    </StyledMainContainer>
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
